import * as React from "react"
import { useState } from "react"
import { Link } from "gatsby"
import { useUpdateEffect } from "react-use"

import Container from "@components/elements/container"
import Cta from "@components/elements/cta"
import Select from "@components/elements/select"

import ArrowRight from "@images/arrowRight.inline.svg"

import styles from "./researchesList.module.scss"

interface ResearchesListProps {
  title?: string
  items: Array<ResearchProps>
}

export interface ResearchProps {
  id: string
  slug: string
  title: string
  text?: string
  type: string
  published: string
}

const ALL_CATEGORY = "All"
const ITEMS_PER_PAGE = 3

const ResearchItem = ({
  id,
  slug,
  title,
  text,
  type,
  published,
}: ResearchProps) => (
  <Link className={styles.item} to={`/research/${slug}`} key={id}>
    {type && <div className={styles.itemType}>{type}</div>}
    {published && <div className={styles.itemDate}>{published}</div>}

    {title && <h2 className={styles.itemTitle}>{title}</h2>}
    {text && <div className={styles.itemText}>{text}</div>}

    <ArrowRight className={styles.itemIcon} />
  </Link>
)

const calculatePagesCount = totalCount => {
  return Math.ceil(totalCount / ITEMS_PER_PAGE)
}

const ResearchesList = ({ title, items }: ResearchesListProps) => {
  const itemsNr = items.length

  if (itemsNr === 0) return

  const [itemsToDisplay, setItemsToDisplay] = useState(
    items.slice(0, ITEMS_PER_PAGE)
  )
  const [activeCategory, setActiveCategory] = useState(ALL_CATEGORY)
  const filterCategories = [
    ALL_CATEGORY,
    ...new Set(items.map(item => item.type)),
  ]
  const [pagination, setPagination] = useState({
    currentPage: 1,
    pagesCount: calculatePagesCount(itemsNr),
  })

  const showMore = () => {
    const counter = itemsToDisplay.length + ITEMS_PER_PAGE
    const newItems = items.slice(0, counter)
    const nextPage = pagination.currentPage + 1

    setPagination({
      ...pagination,
      currentPage: nextPage,
    })
    setItemsToDisplay(newItems)
  }

  useUpdateEffect(
    function changeCategory() {
      let filteredItems = items

      if (activeCategory !== ALL_CATEGORY) {
        filteredItems = filteredItems.filter(item => {
          return item.type === activeCategory
        })
      }

      setPagination({
        currentPage: 1,
        pagesCount: calculatePagesCount(filteredItems.length),
      })
      setItemsToDisplay(filteredItems.slice(0, ITEMS_PER_PAGE))
    },
    [activeCategory]
  )

  return (
    <section className={styles.researchesList}>
      <Container className={styles.container} isNarrow>
        {title && <h1 className={styles.title}>{title}</h1>}

        <Select
          items={filterCategories}
          activeItem={activeCategory}
          setActiveItem={setActiveCategory}
        />
        <div className={styles.list}>
          {itemsToDisplay.map(item => (
            <ResearchItem {...item} key={item.id} />
          ))}
        </div>

        {pagination.pagesCount > 1 &&
          pagination.currentPage < pagination.pagesCount && (
            <Cta
              className={styles.buttonMore}
              text="Show more"
              variant="secondary"
              onClick={showMore}
            />
          )}
      </Container>
    </section>
  )
}

export default ResearchesList
