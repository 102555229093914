import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import styles from "./glowBackground.module.scss"

interface GlowBackgroundProps {
  children?: React.ReactNode
}

const GlowBackground = ({ children }: GlowBackgroundProps) => {
  if (!children) {
    return null
  }

  return (
    <div className={styles.glowBackground}>
      {children}
      <StaticImage
        className={styles.background}
        objectPosition="left top"
        src="../../images/bg-light-dots.png"
        placeholder="none"
        alt=""
      />
      <StaticImage
        className={styles.background}
        objectPosition="right top"
        src="../../images/bg-brand-texture.png"
        placeholder="none"
        alt=""
      />
    </div>
  )
}

export default GlowBackground
