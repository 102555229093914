import * as React from "react"
import { graphql } from "gatsby"

import Layout from "@components/layout/layout"
import ResearchesList from "@components/researchesList"
import GlowBackground from "@components/elements/glowBackground"

const MAX_ABBREVIATION_LENGTH = 180

const Research = ({ data }: any) => {
  const { meta_title, meta_description, meta_image, researches_list } =
    data.prismicInsights.data

  const researchesItems = researches_list.map(({ insight }) => {
    let abbreviationText = ""

    for (let el of insight.document.data.body) {
      if (abbreviationText === "" && el.slice_type === "paragraph") {
        if (abbreviationText === "") abbreviationText = el.primary.content.text
      }

      if (abbreviationText !== "") {
        break
      }
    }

    abbreviationText =
      abbreviationText.length > MAX_ABBREVIATION_LENGTH
        ? `${abbreviationText.substring(0, MAX_ABBREVIATION_LENGTH)}...`
        : abbreviationText

    return {
      id: insight.document.id,
      slug: insight.document.uid,
      title: insight.document.data.title.text,
      published: insight.document.data.published,
      type: insight.document.data.type,
      postType: "research",
      text: abbreviationText,
    }
  })

  return (
    <Layout
      title={meta_title}
      description={meta_description}
      metaImage={meta_image?.url}
    >
      <GlowBackground>
        <ResearchesList title="Research" items={researchesItems} />
      </GlowBackground>
    </Layout>
  )
}

export default Research

export const pageQuery = graphql`
  query {
    prismicInsights {
      data {
        meta_title
        meta_description
        meta_image {
          url
        }
        researches_list {
          insight {
            document {
              ... on PrismicInsight {
                id
                uid
                data {
                  title {
                    text
                  }
                  published(formatString: "DD.MM.YY")
                  type
                  body {
                    ... on PrismicInsightDataBodyParagraph {
                      primary {
                        content {
                          text
                        }
                      }
                      slice_type
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
